.song-display-content {
  font-family: Arial, Helvetica, sans-serif;
}

.song-body {
  white-space: nowrap;
  overflow-x: scroll;
}

.subtitle, .media-links {
  font-style: italic;
  line-height: 2;
}

.media-links-title {
  margin-right: 0.5em;
}

.song-chords {
  font-weight: bold;
  white-space: pre;
  margin-top: 0.5rem;
}

.song-section {
  font-style: italic;
  margin: 2rem 0 0.5rem;
}

.song-lyrics {
  line-height: 2;
}

/* Settings
 * -------- */

.song-display-settings {
  display: flex;
  flex-flow: row wrap;
  align-items: center; /* vertical */
  padding: 0.5rem 0;

  font-size: 0.9rem;
  color: var(--dark-gray);
  user-select: none;
  border-bottom: 1px dotted var(--gray);
}

.song-display-settings > * {
  margin: 0 1rem 1rem 0;
}

.edit {
  padding: 0.25rem 0.5rem;
  border: 1px solid var(--gray);
  border-radius: var(--border-radius);
  color: var(--dark-gray);
  text-decoration: none;
}

/* Annotation settings
 * ------------------- */

.annotation-settings {
  display: grid;
  grid-gap: 1rem;
  grid-auto-flow: column;
  align-items: center; /* vertical */
  justify-content: start; /* horizontal */
}

.annotation-modes {
  display: grid;
  grid-auto-flow: column;
  border: 1px solid var(--gray);
  border-radius: var(--border-radius);
  cursor: pointer;
}

.annotation-mode {
  padding: 0.25rem 0.5rem;
  color: var(--gray);
}

.annotation-mode.selected {
  color: var(--dark-gray);
}

/* Key settings
 * ------------ */

.key-settings {
  display: grid;
  grid-auto-flow: column;
  align-items: center; /* vertical */
  justify-content: start; /* horizontal */
}

.mods {
  display: inline-grid;
  grid-auto-flow: column;
  border: 1px solid var(--gray);
  border-radius: var(--border-radius);
  align-items: center; /* vertical */
  margin: 0 1rem;
}

.mod {
  padding: 0.25rem 0.5rem;
  cursor: pointer;
}

.mod:first-child {
  border-right: 1px solid var(--gray);
  margin-right: 0.5rem;
}

.mod:last-child {
  border-left: 1px solid var(--gray);
  margin-left: 0.5rem;
}

.key {
  min-width: 1.5rem; /* don't move when 1ch to 2chs from E -> Gb */
  text-align: center;
  font-weight: bold;
}

.key-reset {
  display: inline-block;
  border: 1px solid var(--gray);
  border-radius: var(--border-radius);
  color: var(--gray);
  padding: 0.25rem 0.5rem;
  cursor: pointer;
}

.key-reset.able {
  color: var(--dark-gray);
}
