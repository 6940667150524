.brand-link {
  border: 1px solid var(--blue);
  border-radius: var(--border-radius);
  color: var(--blue);
  padding: 0.25em 0.5em;
  margin-right: 0.5em;
  transition: 200ms;
}

.brand-link:hover {
  background: var(--blue);
  color: var(--white);
}
