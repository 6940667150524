.song {
    display: flex;
    justify-content: space-between;
}

.song-content {
    width: 45%;
}

.song-text {
    width: 100%;
    height: 800px;
    font-family: 'Courier New', Courier, monospace;
    outline: none;
}

.edit-song-display {
    display: flex;
    font-size: 10px;
    width: 50%;
}

.edit-song-display-content {
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
}
