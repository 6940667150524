/* .header {
    display: flex;
    flex-direction: row;
} */

.header {
  padding: var(--padding);
  background-color: var(--olive);
}

.header-content {
  max-width: var(--max-width);
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr auto;
}

.header-logo a img {
  background-color: var(--white);
  padding: 0.5rem;
  border-radius: var(--border-radius);
}

.header-logo a img {
  max-width: 100px;
  max-height: 25px;
}


.header-nav-buttons {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;
  align-items: center; /* vertical */
}

.header-nav-buttons a {
  color: var(--white);
}

.footer-social-media-links a {
  padding: 0 2px;
}

/* #submit-song-button {
    background-color: tomato;
    border-radius: 3px;
    border: none;
    outline: none;
    color: white;
    padding: 5px;
    text-align: center;
    font-size: 16px;
    margin: 4px 2px;
    transition: 0.3s;
} */
/* 
#submit-song-button:hover {
    background-color: tomato;
    color: white;
} */
