:root {
  /* Colors */
  --gray: #ccc;
  --dark-gray: #555;
  --white: white;

  /* See https://clrs.cc/ for more colors from this palette */
  --olive: #3D9970;
  --fuchsia: #F012BE;
  --blue: #0074D9;

  /* Other */
  --max-width: 700px;
  --padding: 1rem;
  --border-radius: 0.25rem;
}

body, p {
  margin: 0;
}

.app {
  /* Lovingly borrowed from GitHub */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.content {
  max-width: var(--max-width);
  padding: var(--padding);
  margin: 0 auto;
}

::placeholder {
  color: var(--gray);
}
