.home {
    display: grid;
    grid-auto-flow: row;
}

.song-previews {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
    grid-gap: 0.75rem;
    margin-top: 1.5rem;
}

.song-preview {
    padding: 1rem;
    text-align: center;
}

.song-link {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid var(--olive);
    border-radius: var(--border-radius);
    color: var(--olive);
    text-decoration: none;
    transition: 200ms;
}

.song-link:hover {
    background-color: var(--olive);
    color: var(--white);
}

.song-has-chords {
  position: absolute;
  opacity: 0.15;
  right: 1rem;
  font-size: 1.5rem;
}

#search-bar {
    padding: 0.5rem 1rem;
    margin: 0.5rem 1rem;
    font-size: 0.9rem;
    border: 1px solid var(--gray);
    outline: none;
    transition: 200ms;
}

#search-bar:focus {
    border: 1px solid var(--dark-gray);
}

.song-help {
  margin: 0 1rem;
  font-size: 0.8rem;
  color: var(--gray);
}
